import { BrowserRouter, Navigate, Route, Routes, useParams } from 'react-router-dom';
// import Footer from './Parts/Footer';
import './App.css'
import {PasswordSent} from './Components/Pages/User/PasswordSent'
import {MailDelivery} from './Components/Pages/User/MailDelivery'
import {PasswordReset} from './Components/Pages/User/PasswordReset'
import {ChangePassword} from './Components/Pages/User/ChangePassword'
import {Login} from './Components/Pages/User/Login'
import Confirmation from './Components/Pages/User/Confirmation';
import {Profile} from './Components/Pages/User/Profile';
import {Dashboard} from './Components/Pages/User/Dashboard'
import {Register} from './Components/Pages/User/Register'
import NavBar from './Parts/Nav';
import { useDispatch, useSelector } from 'react-redux';
import { Home } from './Home';
import { useEffect } from 'react';
import { getUser, logOut } from './Redux/Slices/AuthSlice';


function App() {
  const user = useSelector(state=>state.auth.user);
const dispatch = useDispatch()
  const token = JSON.parse(localStorage.getItem('token'))
  useEffect(()=>{
    if(!user&&token){
       dispatch(getUser({token:token}))
    }else if(user===undefined&&token===undefined){
       dispatch(logOut())
    }
  },[token])

  console.log('check this out',user)
  return (
  <div className='app'>
    <BrowserRouter>
     <div style={{marginBottom:'100px'}}>
     <NavBar/>
     </div>
      <Routes>
<Route path='/' element={<Home />} />
<Route path='/:token' element={<Home />} />
<Route path = '/:token/:email/authentication' element={user?<Navigate replace to = {`/${user?._id}/profile`}/>:<Confirmation />}/>
<Route path='/:userId/profile' element ={user===null?<Navigate replace to='/login'/>:<Profile />}/>
<Route path='/:userId/dashboard' element ={user?<Dashboard />:<Navigate replace to='/login'/>}/>
<Route path='/login' element={user?<Navigate replace to = {`/${user?._id}/profile`} />:<Login />} />
<Route path='/register' element={user?<Navigate replace to = {`/${user?._id}/profile`}/>:<Register />} />
<Route path='/:email/mail-delivery' element={<MailDelivery/>} />
<Route path='/:email/password-sent' element={<PasswordSent/>} />
<Route path ='/:email/:token/password-reset' element={<PasswordReset />} />
<Route path='/password-change' element={<ChangePassword />} />
<Route path='*' element={<Home />} />
</Routes>
<div className='footer-section'>
{/* <Footer/> */}
</div>
</BrowserRouter>
  </div>
  );
}

export default App;