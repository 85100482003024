import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { loadUser, loginUser } from '../../../Redux/Slices/AuthSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { clientUrl, url } from '../../../Redux/Api';

const Login = props => {
    const[email, setEmail] = useState('')
    const[password, setPassword] = useState('')
    const loginError = useSelector(state=>state.auth.loginError)
    const[loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const[newState, setNewState] = useState({status:'',data:''});

const loginFunc = async(e)=>{
    e.preventDefault()
    setLoading(true)
    async function fetchData(){
      await fetch(`${url}/login`,{
          mode:'cors',
          method:'post',
          headers:{
              'Content-Type':'application/json'
          },body:JSON.stringify({
            email,password,agent:clientUrl
          })
      }).then(res=>res.json())
      .then(resp=>{
          if(resp.user){
              setLoading(false)
              dispatch(loadUser({user:resp.user,token:resp.token}));
              navigate(-1)
          }else if(resp.err){
              setLoading(false)
              return setNewState({status:'failed',data:resp.err})
          }else{
              setLoading(false)
              return setNewState({status:'failed',data:'Something went wrong.'})
          }
      }).catch(err=>{setNewState({status:'failed',data:err.message});setLoading(false)})
  }
  fetchData();
}
return <Container className='auth-container'>
        <form onSubmit={loginFunc} className='auth-form'>
          <p>{loginError}</p>
            <p style={{textAlign:'center'}}>{newState.data.length>0?newState.data:'Login'}</p>
        <div className='inputs-wrapper'>
        <div className="mb-3 row">
    <label htmlFor="email" className="col-sm-2 col-form-label">Email</label>
    <div className="col-sm-10">
      <input className="form-control" onChange={(e) => setEmail(e.target.value)} id="email" 
      type='email'
      required
      />
    </div>
    </div>
<div className="mb-3 row">
    <label htmlFor="password" className="col-sm-2 col-form-label">Password</label>
    <div className="col-sm-10">
      <input className="form-control" onChange={(e) => setPassword(e.target.value)} id="password" 
      type='password'
      required
      />
    </div>
    </div>
  </div>
        {
            loading?
            <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>:<Button type='submit' className='auth-button'>Login</Button>
        }
        </form>
        <Row>
        <Col style={{marginTop:'20px'}}>Don't have an account? <a style={{textDecoration:'none'}} href='/register'>Create Account</a></Col>
        <Col style={{marginTop:'20px'}}>Forgotten password? <a style={{textDecoration:'none'}} href='/password-change'>Reset Password</a></Col>
        </Row>
    </Container>;
};

Login.propTypes = {};

export { Login };
