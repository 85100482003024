import React, {useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import placeholder from '../../../assets/placeholder.png';
import { Button, ListGroup, ListGroupItem, Tab, Tabs } from 'react-bootstrap';
import {  useNavigate } from 'react-router-dom';
import { url } from '../../../Redux/Api';
import './Profile.css';


const Profile = props => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector(state=>state.auth.user);
    const[active, setActive] = useState('subscriptions');
    const[loading, setLoading] = useState(false);
    const[newState, setNewState] = useState({status:'',data:''});
    const[profilePicPreview,setProfilePicPreview] = useState('')


    function handleChangeProfilePic(e){
      e.preventDefault()
      setLoading(true)
      async function fetchData(){
              await fetch(`${url}/change-profile-picture`,{
                  mode:'cors',
                  method:'put',
                  headers:{
                      'Content-Type':'application/json',
                      'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
                  },body:JSON.stringify({
src:profilePicPreview
                  })
              }).then(res=>res.json())
              .then(resp=>{
                  if(resp.resp){
                      setLoading(false)
                      setNewState({status:'success',data:resp.resp})
                      return window.location.reload()
                  }else if(resp.err){
                      setLoading(false)
                      return setNewState({status:'failed',data:resp.err})
                  }else{
                      setLoading(false)
                      return setNewState({status:'failed',data:'Something went wrong.'})
                  }
              }).catch(err=>{setNewState({status:'failed',data:err.message});setLoading(false)})
          }
          fetchData();
  }

  const handleProfilePic = async (a)=>{
    const data = a.target.files[0];
    const base64 = await ConvertProfilePic(data);
    setProfilePicPreview(base64);
    
  }
  const ConvertProfilePic = (e)=>{
    return new Promise((resolve,reject)=>{
      const reader = new FileReader()
      reader.readAsDataURL(e)
      reader.onload = ()=>{
        resolve(reader.result)
      }
      reader.onerror = (error)=>{
        reject(error)
      }
    })
  }


 return <Container className='profile-container'>
  <div className='profile-img-wrapper'>
  <input type='file' onChange={handleProfilePic}  className='img-overlay' />
                {/* {user?.image?.toString()!=='image'?
                 <img src={user?.image} thumbnail className='profile-img' />:
                 <div className='profile-img-wrapper'>
<img src={profilePicPreview.length>0?profilePicPreview:placeholder} thumbnail className='profile-img' />
{profilePicPreview.length>0&&<button className='profile-btn' onClick={handleChangeProfilePic}>Ok</button>}
                 </div>
    } */}
               {profilePicPreview.length>0?
                 <img src={profilePicPreview} thumbnail className='profile-img' />:
                 <div className='profile-img-wrapper'>
<img src={user?.image=='image'?placeholder:user?.image} thumbnail className='profile-img' />
                 </div>
    }
    {profilePicPreview.length>0&&<button className='profile-btn' onClick={handleChangeProfilePic}>Ok</button>}
  </div>
   
    <Row>
     <div style={{marginBottom:'50px'}}/>
       <Tabs
        defaultActiveKey={active==='subscriptions'?'subscriptions':active==='subscriptions'?'subscriptions':'user'}
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <h2>{newState.data}</h2>
        <Tab eventKey="user" title="User Details" onClick={()=>setActive('user')}>
        <ListGroup>
        <ListGroupItem>{user?.userName}</ListGroupItem>
        <ListGroupItem>{`${user?.firstName} ${user?.middleName} ${user?.lastName}`}</ListGroupItem>
              <ListGroupItem>{user?.phone1}</ListGroupItem>
              <ListGroupItem>{user?.email}</ListGroupItem>
              <ListGroupItem>{user?.eligible?'Verified Account':'Not verified'}</ListGroupItem>
          </ListGroup>
        </Tab>
      </Tabs>
    </Row>
        </Container>;
};
Profile.propTypes = {};
export { Profile };