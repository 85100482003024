import PropTypes from 'prop-types';
import { Container, Row } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import './Home.css';
import { useDispatch, useSelector } from 'react-redux';
import { agentsClientUrl, brandingClientUrl, resClientUrl } from './Redux/Api';
import { useEffect } from 'react';
import { getUser } from './Redux/Slices/AuthSlice';

const Home = () => {
    const user = useSelector(state=>state.auth.user);
    const{token} = useParams()
    const dispatch = useDispatch()
      
    useEffect(()=>{
        if(!user&&token){dispatch(getUser({token:token}))}
      },[token])

const handleClick = e=>{
    if(e==='branding'){
        if(!user){
            return window.location.replace(`${brandingClientUrl}`)
        }else{
            return window.location.replace(`${brandingClientUrl}/${user?.token}`)
        }
    }else if(e==='agents'){
        if(!user){
            return window.location.replace(`${agentsClientUrl}`)
        }else{
            return window.location.replace(`${agentsClientUrl}/${user?.token}`)
        }
    }else if(e==='res'){
        if(!user){
            return window.location.replace(`${resClientUrl}`)
        }else{
            return window.location.replace(`${resClientUrl}/${user?.token}`)
        }
    }
}
    return (<Container className='home-main'>
        <Row className='home-group'>
        <Link  className='home-item' onClick={()=>handleClick('branding')} >Business Branding</Link>
        <Link  className='home-item' onClick={()=>handleClick('res')} >Event Space</Link>
        <Link  className='home-item' onClick={()=>handleClick('agents')} >Property Agent</Link>
        <span  className='home-item' >Workplace</span>
        <span  className='home-item' >Companion</span>
        <span  className='home-item' >Flash back</span>
        <span  className='home-item' >X find X</span>
        <span  className='home-item' >Campush</span>
        <span  className='home-item' >Messenger</span>
        <span  className='home-item' >Invest</span>
        <span  className='home-item' >Family</span>
        <span  className='home-item' >Publication</span>
        <span  className='home-item' >Library</span>
        <span  className='home-item' >Swapper</span>
        <span  className='home-item' >Formatly</span>
        </Row>
    </Container>);
};

Home.propTypes = {};

export { Home };